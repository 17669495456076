import React, { useState } from 'react';
import styled, { css } from 'styled-components';

const fontStyle = css`
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.72px;
`;

const InputWrapper = styled.div`
  position: relative;
  height: 20px;
`;

const Placeholder = styled.span`
  ${fontStyle}
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #686868;
  pointer-events: none;
`;

const StyledInput = styled.input`
  ${fontStyle}
  width: 100%;
  height: 20px;
  color: #fff;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
`;

interface ToolbarTextboxProps {
  placeholder: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ToolbarTextboxMolecule = (props: ToolbarTextboxProps) => {
  const { placeholder, onChange } = props;
  const [value, setValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <InputWrapper>
      {value === '' && <Placeholder>{placeholder}</Placeholder>}
      <StyledInput type="text" value={value} onChange={handleChange} />
    </InputWrapper>
  );
};
