import React from 'react';
import styled from 'styled-components';
import { AtlasBodyMain } from '../../atoms';

const TagContainer = styled.div<{
  $color: string;
  $bg: string;
  $shape: 'default' | 'rounded';
  $roundedSize: number;
}>`
  display: flex;
  padding: ${({ $shape }) => ($shape === 'default' ? '4px 8px' : undefined)};
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: ${({ $shape, $roundedSize }) =>
    $shape === 'default' ? 'fit-content' : `${$roundedSize}px`};
  height: ${({ $shape, $roundedSize }) => ($shape === 'default' ? undefined : `${$roundedSize}px`)};

  background-color: ${({ $bg }) => $bg};
  color: ${({ $color }) => $color};
  border-radius: ${({ $shape }) => ($shape === 'default' ? '4px' : '50%')};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

interface TagMoleculeProps extends React.HTMLAttributes<HTMLDivElement> {
  text?: string;
  shape?: 'default' | 'rounded';
  bgColor?: string;
  textColor?: string;
  icon?: JSX.Element;
  roundedSize?: number;
}

export const TagMolecule: React.FC<TagMoleculeProps> = ({
  text,
  shape = 'default',
  bgColor = '#5C5C5C',
  textColor = '#FFF',
  icon,
  roundedSize = 24,
  ...rest
}) => {
  return (
    <TagContainer
      $color={textColor}
      $bg={bgColor}
      $shape={shape}
      $roundedSize={roundedSize}
      {...rest}
    >
      {icon}
      {shape === 'default' && text && <AtlasBodyMain>{text}</AtlasBodyMain>}
    </TagContainer>
  );
};
