import styled from 'styled-components';
import { Icon } from '../atoms/icons/Icon';
import { ProgressBar } from '../atoms/ProgressBar.atom';
import { AtlasBodySubtitle, AtlasBodyTitle } from '../atoms/Typography/Typography.atom';
import { AtlasColor } from '../foundations/Colors.foundations';
import { SVGComponent } from '../@types/Svg.component';
import React from 'react';
import { FlexRowAtom } from '../atoms/FlexRow.atom';
import { ToolbarTextboxMolecule } from './ToolbarTextbox/ToolbarTextbox.molecule';

const ToolbarComponentStyle = styled.div`
  flex-grow: 0;
  height: 56px; //56
  background-color: ${AtlasColor.Background};
  display: flex;
  flex-direction: row;
  padding: 16px;
  align-items: center;
  justify-content: center;
  box-shadow: ${AtlasColor.BoxShadow};
`;

const ToolbarTitle = styled.div`
  flex-grow: 1;
  padding-left: 16px;
`;

const OnlyToolbarTitle = styled.div`
  flex-grow: 1;
`;

const SecondaryLabel = styled(AtlasBodySubtitle)`
  margin-right: 8px;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface ToolbarComponentProps {
  'title': string;
  'actionIcon': SVGComponent;
  'secondaryActionIcon'?: SVGComponent;
  'thirdActionIcon'?: SVGComponent;
  'onAction'?: () => void;
  'onSecondaryAction'?: () => void;
  'onThirdAction'?: () => void;
  'progress'?: number;
  'data-test'?: string;
  'secondaryLabel'?: string;
  'searchMode'?: boolean;
  'onChangeSearch'?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ToolbarMolecule = (props: ToolbarComponentProps) => {
  const {
    title,
    actionIcon,
    secondaryActionIcon,
    thirdActionIcon,
    onAction,
    onSecondaryAction,
    onThirdAction,
    progress,
    secondaryLabel,
    searchMode = false,
    onChangeSearch,
  } = props;
  const hasAction = Boolean(actionIcon);
  return (
    <ToolbarComponentStyle data-test={props['data-test']}>
      {hasAction && (
        <Icon src={actionIcon} onClick={onAction} data-test={props['data-test'] + '-action-icon'} />
      )}
      {hasAction && !searchMode && (
        <ToolbarTitle>
          <AtlasBodyTitle data-test={props['data-test'] + '-title'}>{title}</AtlasBodyTitle>
        </ToolbarTitle>
      )}
      {!hasAction && !searchMode && (
        <OnlyToolbarTitle>
          <AtlasBodyTitle data-test={props['data-test'] + '-title'}>{title}</AtlasBodyTitle>
        </OnlyToolbarTitle>
      )}

      {searchMode && (
        <ToolbarTitle>
          <ToolbarTextboxMolecule placeholder={title} onChange={onChangeSearch} />
        </ToolbarTitle>
      )}

      <FlexRowAtom $gap={16} $alignItems="center">
        {secondaryLabel && (
          <SecondaryLabel data-test={props['data-test'] + '-subtitle'}>
            {secondaryLabel}
          </SecondaryLabel>
        )}
        {secondaryActionIcon && (
          <Icon
            src={secondaryActionIcon}
            onClick={onSecondaryAction}
            data-test={props['data-test'] + '-secondary-action-icon'}
          />
        )}
        {thirdActionIcon && (
          <Icon
            src={thirdActionIcon}
            onClick={onThirdAction}
            data-test={props['data-test'] + '-third-action-icon'}
          />
        )}
      </FlexRowAtom>
      {progress !== undefined && (
        <ProgressBar progress={progress || 0} data-test={props['data-test'] + '-progress-bar'} />
      )}
    </ToolbarComponentStyle>
  );
};
