import React, { useEffect, useState } from 'react';

import { AppMenuMolecule, AppToolbarMolecule, ToolbarComponentProps } from '../../molecules';
import { MenuItem } from './helpers';
import styled from 'styled-components';

const countryToLanguage: Record<string, string> = {
  mx: 'es',
  pe: 'es',
  br: 'pt',
};

interface NavigationMenuProps extends ToolbarComponentProps {
  userName: string;
  userEmail: string;
  country: string;
  warehouseName: string;
  version: string;
  menuItems: MenuItem[];
  onLogout: () => void;
}

const NavigationMenuContainer = styled.div`
  position: relative;
`;

export const NavigationMenuOrganism = (props: NavigationMenuProps) => {
  const { userName, userEmail, country, warehouseName, version, menuItems, onLogout, ...toolbar } =
    props;

  const [lang, setLang] = useState('es');
  const [isMenuOpen, setMenuOpen] = useState(toolbar.isMenuOpen);

  useEffect(() => {
    const newLangValue = countryToLanguage[country.toLowerCase()];
    setLang(newLangValue);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <NavigationMenuContainer>
      <AppToolbarMolecule {...toolbar} isMenuOpen={isMenuOpen} onAction={toggleMenu} />
      {isMenuOpen && (
        <AppMenuMolecule
          userName={userName}
          userEmail={userEmail}
          countryName={country}
          warehouseName={warehouseName}
          version={version}
          menuItems={menuItems}
          lang={lang}
          onLogout={onLogout}
          onClose={toggleMenu}
        />
      )}
    </NavigationMenuContainer>
  );
};
