import React from 'react';
import styled from 'styled-components';

import { AtlasBodyMain } from '../../atoms';
import { AtlasColor } from '../../foundations/Colors.foundations';

const ChipContainer = styled.div<{
  $selected: boolean;
  $bgColor: string;
  $borderColor: string;
  $textColor: string;
  $activeBgColor: string;
  $activeBorderColor: string;
  $activeTextColor: string;
}>`
  display: flex;
  gap: 8px;
  border-radius: 48px;
  padding: 6px 12px;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  background-color: ${({ $selected, $bgColor, $activeBgColor }) =>
    $selected ? $activeBgColor : $bgColor};
  border: 1px solid
    ${({ $selected, $borderColor, $activeBorderColor }) =>
      $selected ? $activeBorderColor : $borderColor};
  color: ${({ $selected, $textColor, $activeTextColor }) =>
    $selected ? $activeTextColor : $textColor};

  &:hover {
    opacity: 0.8;
  }
`;

interface ChipMoleculeProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: 'default' | 'outlined';
  text?: string;
  icon?: JSX.Element;
  selected?: boolean;
  bgColor?: string;
  borderColor?: string;
  textColor?: string;
  activeBgColor?: string;
  activeBorderColor?: string;
  activeTextColor?: string;
}

export const ChipMolecule: React.FC<ChipMoleculeProps> = ({
  type = 'default',
  selected = false,
  text,
  icon,
  bgColor,
  borderColor,
  textColor,
  activeBgColor,
  activeBorderColor,
  activeTextColor,
  ...rest
}) => {
  return (
    <ChipContainer
      $selected={selected}
      $bgColor={bgColor ?? (type === 'outlined' ? 'transparent' : '#5C5C5C')}
      $borderColor={borderColor ?? (type === 'outlined' ? '#5C5C5C' : 'none')}
      $textColor={textColor ?? (type === 'outlined' ? '#5C5C5C' : '#FFF')}
      $activeBgColor={activeBgColor ?? '#3C2E02'}
      $activeBorderColor={activeBorderColor ?? AtlasColor.Primary00}
      $activeTextColor={activeTextColor ?? AtlasColor.Primary00}
      {...rest}
    >
      {icon}
      {text && <AtlasBodyMain style={{ fontWeight: 700 }}>{text}</AtlasBodyMain>}
    </ChipContainer>
  );
};
